import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {graphql} from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'

export const query = graphql`
  query NotFoundPageQuery {

    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title {
          _key
          _type
          en
          de
        }
      description
      keywords
      callToActionActive
      callToActionButtonText
      callToActionText
      mainImage {
      asset {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      }
      seperatorImage {
        asset {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }

    menu:  sanityMenu(_id: {regex: "/(drafts.|)menu/"}) {
      id
      menuitems {
        _key
        _type
        name {
          _key
          _type
          de
          en
        }
        url
        menutype
      }
      menuName
    }

    socialMedia: sanitySocialMedia {
      id
      socialMediaItems {
        _key
        _type
        url
        name
      }
    }

  }
`

const NotFoundPage = (props) => {
  const {data, errors, pageContext} = props
  const menu = (data || {}).menu
  const site = (data || {}).site
  const socialMedia = (data || {}).socialMedia

  if (errors) {
    return (
      <Layout language={pageContext.intl.language} menus={menu} pageContext={pageContext} site={site} callToAction={false} socialMedia={socialMedia} isSubPage >
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout language={pageContext.intl.language} menus={menu} pageContext={pageContext} site={site} callToAction={false} socialMedia={socialMedia} isSubPage >
      <SEO title='404: Not found' />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage
